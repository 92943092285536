import "bootstrap/dist/css/bootstrap.min.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "./App.css";
import {NavBar} from "./Components/NavBar";
import {Banner} from "./Screens/Banner";
import {Skills} from "./Screens/Skills";
import {Credentials} from "./Screens/Credentials";
import {Experience} from "./Screens/Experience";
import {Projects} from "./Screens/Projects";
import {Contact} from "./Screens/Contact";
import {Footer} from "./Components/Footer";
import Agb from "./Screens/Legal/Agb";
import Privatepolicy from "./Screens/Legal/Privacypolicy";
import Legalnotice from "./Screens/Legal/Legalnotice";

function App() {
    return (
        <Router>
            <div className="App">
                <NavBar/>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                <Banner/>
                                <Credentials/>
                                <Skills/>
                                <Experience/>
                                <Projects/>
                                <Contact/>
                            </>
                        }
                    />
                    <Route path="/Agb" element={<Agb/>}/>
                    <Route path="/Privatepolicy" element={<Privatepolicy/>}/>
                    <Route path="/Legalnotice" element={<Legalnotice/>}/>
                </Routes>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;
