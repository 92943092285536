import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import React from "react";
import {useImage} from "react-image";
import projectImg1 from "../Images/Project_1.webp";
import projectImg2 from "../Images/Project_2.webp";
import projectImg3 from "../Images/ProjectImg3.jpg";
import projectImg4 from "../Images/ProjectImg4.jpg";
import projectImg5 from "../Images/ProjectImg5.jpg";
import {ProjectCard} from "../Components/ProjectCard";

export const Projects = () => {
    /*Lade das Bild vorher*/
    const {src: projIMG_1} = useImage({srcList: [projectImg1]});
    const {src: projIMG_2} = useImage({srcList: [projectImg2]});
    const {src: projIMG_3} = useImage({srcList: [projectImg3]});
    const {src: projIMG_4} = useImage({srcList: [projectImg4]});
    const {src: projIMG_5} = useImage({srcList: [projectImg5]});

    /*TAB 1 ALL FINISHED PROJECTS*/
    const completedProjects = [
        {
            toptitle: "Blockchain NGAC (Bachelor's Thesis)",
            title: "Smart Contract-Based NGAC",
            description:
                "An attribute-based access control system developed using smart contracts.",
            imgUrl: projIMG_1,
            url: "https://github.com/StefGvxds/NGAC-Blockchain/",
        },
        {
            toptitle: "This Portfolio website",
            title: "My Portfolio website",
            description:
                "A showcase of my professional journey, projects, and expertise.",
            imgUrl: projIMG_2,
        },
        {
            toptitle: "JokerGame-Stats",
            title: "university project (Java)",
            description: "User-friendly GUI app for Greek lottery statistics.",
            imgUrl: projIMG_3,
            url: "https://github.com/StefGvxds/JokerGame-Stats",
        },
        {
            toptitle: "My Python mini-projects",
            title: "Python Projects",
            description: "A compilation of my Python projects.",
            imgUrl: projIMG_4,
            url: "https://github.com/StefGvxds/SmallPythonProjects",
        },
        {
            toptitle: "React Native Todo Application",
            title: "Todo App",
            description: "Add and Edit todos",
            imgUrl: projIMG_5,
            url: "https://github.com/StefGvxds/React-Native-To-Do-List-Application.git",
        },
    ];

    /*TAB 2 ALL UPCOMING PROJECTS*/
    const upcomingProjects = [
        {
            toptitle: "Blockchain NGAC (Continuation of Bachelor's Thesis)",
            title: "Smart Contract-Based NGAC 2.0",
            description:
                "Integrating obligations, prohibitions, and enhancements for system adaptability and security.",
            imgUrl: projectImg5,
            url: "https://github.com/StefGvxds/NGAC-Blockchain/tree/Pro1",
        },
    ];

    return (
        <section className="project" id="project">
            <Container className="projectContainer">
                <Row>
                    <Col size={12}>
                        <h2>Projects</h2>
                        <Tab.Container id="project-tabs" defaultActiveKey="first">
                            <Nav
                                variant="pills"
                                className="nav-pills mb-5 justify-content-center align-items-center"
                                id="pills-tab"
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Completed</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Upcoming</Nav.Link>
                                </Nav.Item>
                                {/* <Nav.Item>
                  <Nav.Link eventKey="third">Tab 3</Nav.Link>
                </Nav.Item> */}
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {completedProjects.map((project, index) => (
                                            <Col sm={12} md={6} lg={4} key={index}>
                                                <div className="projectWrapper">
                                                    {" "}
                                                    {/* Neuer Wrapping Div */}
                                                    <div className="projectTitle">
                                                        <h4>{project.toptitle}</h4>
                                                    </div>
                                                    <div className="projectCardDiv">
                                                        <ProjectCard {...project} />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {upcomingProjects.map((project, index) => (
                                            <Col sm={12} md={6} lg={4} key={index}>
                                                <div className="projectWrapper">
                                                    {" "}
                                                    {/* Neuer Wrapping Div */}
                                                    <div className="projectTitle">
                                                        <h4>{project.toptitle}</h4>
                                                    </div>
                                                    <div className="projectCardDiv">
                                                        <ProjectCard {...project} />
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};
